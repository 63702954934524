import { useState } from "react"
import MCNav from "../components/MCNav";
import MCContent from "../components/MCContent";

const menudb = require('../data/menudb.json')

function Menu() {

    const [ selectedMC, setSelectedMC ] = useState({}); // category

    return(
        <div className="container">
            <MCContent selectedMC={selectedMC} mdb={menudb}/>
            <MCNav selectedMC={selectedMC} setSelectedMC={setSelectedMC} mdb={menudb}/>
        </div>
    )
}

export default Menu
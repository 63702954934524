import { useState } from "react";

import React from "react";

function SCAccordion(props) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const handleClick = index => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  function VPills(props) {

    function getBadgeColor(vclass) {
      if(vclass === "Veg") return "v-veg"
      if(vclass === "Non Veg") return "v-nonveg"
      if(vclass === "Seafood") return "v-sea"
      if(vclass === "Egg") return "v-egg"
      return "v-default"
      
      // (vclass.toLowercase() === 'veg') ? "v-veg" :
      // (vclass.toLowercase() === 'nonveg') ? "v-nonveg" :
      // (vclass.toLowercase() === 'egg') ? "v-egg" :
      // (vclass.toLowercase() === 'seafood') ? "v-sea" : ""
    }
    
    return(
      <div className="pillContainer mt-auto">
        {
          props.dish.variety.map((v) => {
            return(
              <div className={`badge p-2 fs-6 ${getBadgeColor(v.class)}`}>
                {(v.type) ? v.type : v.class}<span>₹{v.price}</span>
              </div>
            )
          })
        }
      </div>
    )
  }

  function DishCard(props) {
    return(
      <div key={props.dish.name} className="mb-3">
        <div className="card fs-6" >
          {/* TODO: props.dish.imageUrl */}
          <div className="cardImageContainer">
            <img src={"https://ministryofcurry.com/wp-content/uploads/2019/10/paneer-kathi-rolls-1.jpg"} className="card-img-top" alt={props.dish.name} />
          </div>
          <div className="card-body d-flex flex-column">
            <h6 className="card-title fs-5">{props.dish.name}</h6>
            <VPills dish={props.dish}/>
           </div>
        </div>
      </div>
    )
  }

  return (
    <div className="accordion" id="menuAccordion">
      {props.subCategories.map((subCategory, index) => (
        <div className="accordion-item" key={subCategory.name}>
          <h2 className="accordion-header" id={`heading-${index}`}>
            <button
              className={`accordion-button ${index === activeIndex ? '' : 'collapsed'}`}
              type="button"
              onClick={() => handleClick(index)}
            >
              {subCategory.name}
            </button>
          </h2>
          <div
            id={`collapse-${index}`}
            className={`accordion-collapse collapse ${index === activeIndex ? 'show' : ''}`}
            aria-labelledby={`heading-${index}`}
            data-bs-parent="#menuAccordion"
          >
            <div className="accordion-body">
              <div className="d-flex flex-row justify-content-start gap-3 p-1">
                {subCategory.dishes.map(dish => <DishCard dish={dish}/>)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SCAccordion
import Menu from './pages/Menu'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Menu/>
      </header>
    </div>
  );
}

export default App;
import SCAccordion from "./SCAccordion"
import logo from '../resources/treehouselogo.svg'

function MCContent(props) {
    // Menu // props: selectedMC mdb selectedSC setSelectedSC

    function MenuLanding() {
        return(
            <div className="splash d-flex flex-column h-100 justify-content-center align-items-strech">
                <div className="d-flex flex-column m-5">
                    <img src={logo} className="img-fluid" height="100px"alt="The Treehouse Cafe" />
                    <div className="h1 text-center">Experience</div>
                    <div className="h1 typewriter text-center">the Treehouse Vibe.</div>
                    <div className="mt-5 text-center">View Menu<div className="downarrow">⌄</div></div>
                </div>
            </div>
        )
    }

    return(
        <div class="tab-content" id="pills-tabContent">
            {
                (props.selectedMC.length) ?
                props.mdb.map(category => {
                    return(
                        <div className={`tab-pane pt-3 fade ${props.selectedMC === category.name ? "show active" : ""}`}
                            id={`pills-${category.name}`}
                            role="tabpanel" 
                            aria-labelledby={`pills-${category.name}-tab`}>
                            <SCAccordion selectedMC={category.name} subCategories={category.subCategories}/>
                        </div>
                    )
                })
                :
                <MenuLanding/>
            }
        </div>
    )
}

export default MCContent
function MCNav(props) {
    // Menu // props: selectedMC setSelectedMC mdb

    return(
        // TODO: remove justify-center; for placeholder only
        <ul class="catNav nav nav-pills p-2 justify-content-center fs-6 gap-3 fixed-bottom"
            id="pills-tab" role="tablist">
            {
                props.mdb.map(category => {
                    return (
                        <li class="nav-item" role="presentation">
                            <button class={`navButton nav-link ${(props.selectedMC === category.name) ? "active" : ""}`}
                                id={`pills-${category.name}-tab`}
                                data-bs-target={`#pills-${category.name}`}
                                aria-controls={`pills-${category.name}`}
                                data-bs-toggle="pill"
                                aria-selected={props.selectedMC === category.name}
                                type="button"
                                role="tab"
                                onClick={() => props.setSelectedMC(category.name)}>
                                    {category.name}
                                </button>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default MCNav